
"use strict";

(function(){

    var language_switch = document.getElementById('language-switch');


    if (language_switch === null) {
        return;
    }

    language_switch.addEventListener('click', function()
    {
        if (language_switch.classList.contains('is--active')) {
            language_switch.classList.remove('is--active');
        } else {
            language_switch.classList.add('is--active');
        }
    });



}());