"use strict";

/**
 *
 */
(function() {

    var url, wait,
        redirect = document.getElementById('redirect-page');

    if (redirect === null) {
        return;
    }

    url = redirect.getAttribute('data-url');
    wait = redirect.getAttribute('data-wait');

    if (wait === null) {
        wait = 15000;
    } else {
        wait = parseInt(wait);
    }

    // Redirect to the starting page
    function redirectToUrl() {
        window.location.href = url;
    }

    // Redirect on touch
    document.body.addEventListener('click', redirectToUrl);

    // Auto redirect after 15 seconds
    setTimeout(redirectToUrl, wait);
}());