/*jslint white: true, browser: true, plusplus: true, todo: true */
	
/*global require: false */

/* 	Written by: 	Wouter van Dam / Britelayer
	Version:		2.0
	Creation date:	3 Oct 2014
	Updated on: 	10 May 2016
*/

"use strict";

var Gator = require('../../vendor/gator-1.2.4.min.js'),
	PubSub = require('../../vendor/pubsub.js');


(function(Gator, PubSub){
	
	var boundInput = null,
		active = false,
		capitals = 0,
		capKey = document.getElementById('caps_key'),

		keyboard = document.getElementById('keyboard'),

		// Complete list of valid key values
		keys = ['a','b','c','d','e','f','g',
			'h','i','j','k','l','m','n','o',
			'p','q','r','s','t','u','v','w',
			'x','y','z','0','1','2','3','4',
			'5','6','7','8','9',' ','&',
			'backspace', 'enter', 'numbers',
			'left', 'right', 'caps'
		],

		number_row = document.querySelector('#keyboard .zero'),

        // PubSub Channels
        channels = {
			keyboard : {
				stroke 	: 'keyboard.stroke',
				hide   	: 'keyboard.hide',
				show	: 'keyboard.show',
				bind   	: 'keyboard.bind',
				activate: 'keyboard.activate',
				enter	: 'keyboard.enter'
			}
		},

	// ------------------------------------------------------------------------
	// 	Visibility / Active state
	// ------------------------------------------------------------------------

		showKeyboard = function()
		{
			keyboard.classList.add('show');
		},
		hideKeyboard = function()
		{
			keyboard.classList.remove('show');

			if (boundInput !== null) {
				boundInput.blur();
			}
		},
		isKeyboardVisible = function()
		{
			return keyboard.classList.contains('show');
		},

		/**
		 * Depending on where the user clicked the keyboard is hidden
		 *
		 * @param event
		 */
		maybeHideKeyboard = function(event)
		{
			if (event.target == boundInput || event.target == keyboard) {
				return;
			}

			if (isKeyboardVisible()) {

				// This matches any element that is part of the keyboard html
				// Thus, any element of the keyboard does not hide the keyboard
				if (
					event.target.getAttribute('data-key') !== null ||
					event.target.classList.contains('keyboardrow') ||
					event.target.classList.contains('keyboardsmall')
				) {
					return;
				}

				hideKeyboard();
			}
		},

		/**
		 * On the first time an input element is bound the keyboard event handlers are connected to the DOM
		 */
		maybeActivate = function()
		{
			if (active === false) {
				active = true;

				// Hide the keyboard if someone clicks outside the keyboard and input area
				document.body.addEventListener('click', maybeHideKeyboard);

				// Process keystrokes
				Gator(keyboard).on('click', 'span', keyStroke);
			}
		},

	// ------------------------------------------------------------------------
	// 	Binding the Input box
	// ------------------------------------------------------------------------

		/**
		 * Check whether the passed var is an input or textarea element
		 *
		 * @param input
		 * @returns {boolean}
		 */
		isInputElement = function(input)
		{
			return input !== null &&
				typeof input === "object" &&
				"classList" in input &&
				input.nodeType === 1 &&
				(input.tagName === 'INPUT' || input.tagName === 'TEXTAREA');
		},

		/**
		 * Check whether the bound input element actually is an input element
		 *
		 * @returns {boolean}
		 */
		isInputBound = function()
		{
			return isInputElement(boundInput);
		},

		/**
		 * Bind an input element with which to interact
		 *
		 * @param input
		 */
		bindInput = function(input)
		{
			if (isInputElement(input) && input !== boundInput) {

				unbindInput();

				boundInput = input;

				// If the newly bound input box is already focused we immediately show the keyboard
				if (boundInput === document.activeElement) {
					showKeyboard();
				}

				// Activate the keyboard if this is the first bound input box
				maybeActivate();

				// Unless we're in all caps mode we capitalize the first letter
				if (capitals === 0 && input.value === '') {
					toggleCapitals(capKey);
				}

				// Show the keyboard as soon as the bound input is clicked
				boundInput.addEventListener('focus', showKeyboard);
			}
		},

		/**
		 * Clean up the event handler and clear out the input binding
		 */
		unbindInput = function()
		{
			if (isInputBound()) {
				// Disconnect
				boundInput.removeEventListener('focus', showKeyboard);
				boundInput = null;
			}
		},

		/**
		 * Return the bound input field
		 *
		 * @returns {*}
		 */
		getBoundField = function()
		{
			return boundInput;
		},




	// ------------------------------------------------------------------------
	// 	Handle Keystrokes
	// ------------------------------------------------------------------------

		/**
		 * Toggle the visibility of the number rows. This row will cover up the first row of letters
		 */
		toggleNumberRow = function(key)
		{
			if (number_row.classList.contains('hidden')) {
				key.innerText = 'ABC';
				number_row.classList.remove('hidden');
			} else {
				key.innerText = '0 - 9';
				number_row.classList.add('hidden');
			}
		},

		/**
		 * Move the cursor one position to the left
		 *
		 * @param {number} start
		 * @param {number} end
		 */
		moveLeft = function(start, end)
		{
			// If nothing is selected we move start and end
			if (start == end) {
				end = end - 1;
			}

			start = start -1;

			boundInput.focus();
			boundInput.setSelectionRange(start, end);
		},

		/**
		 * Move the cursor one position to the right
		 *
		 * @param {number} start
		 * @param {number} end
		 */
		moveRight = function(start, end)
		{

			// If nothing is selected we move start and end
			if (start == end) {
				start = start + 1;
			}

			end = end + 1;

			boundInput.focus();
			boundInput.setSelectionRange(start, end);
		},

		/**
		 * Toggle the capital flag
		 *
		 * @param key
		 */
		toggleCapitals = function(key)
		{
			capKey = key;

			// If state is caps we turn it off
			if (capitals == 2) {
				key.classList.remove('dark');
				key.innerHTML = 'shift';
				capitals = 0;
				keyboard.classList.remove('uppercase');

			// If state is shift, we go to caps
			} else if (capitals == 1) {
				key.classList.add('dark');
				key.innerHTML = 'caps';
				capitals = 2;
				keyboard.classList.add('uppercase');

			// if state is off, we go to shift
			} else {
				key.innerHTML = 'caps';
				capitals = 1;
				keyboard.classList.add('uppercase');
			}
		},


		/**
		 * Handle a keystroke on the keyboard
		 */
		keyStroke = function()
		{
			var start, end, value, pos,
				key = this.getAttribute('data-key');

			// Not a known key, then exit
			if (
				key == null ||
				-1 == keys.indexOf(key)
			) {
				return;
			}

			// ---------------------------
			//  Special characters
			// ---------------------------

			// Numbers key - toggle number row visibility
			if (key == 'numbers') {
				toggleNumberRow(this);
				return;
			}

			// Caps key - toggle capital flag
			if (key == 'caps') {
				toggleCapitals(this);
				return;
			}

			// Signal a stroke on the enter key
			if (key == 'enter') {
				PubSub.publish(channels.keyboard.enter, {});
				return;
			}

			// The rest of the keys only work with a bound input field
			if ( ! isInputBound()) {
				return;
			}

			// Get the current input value characteristics
			start 	= boundInput.selectionStart;
			end 	= boundInput.selectionEnd;
			value 	= boundInput.value;

			// Move the selected area one to the left or right
			if (key == 'left') {
				moveLeft(start, end);
				return;
			}
			if (key == 'right') {
				moveRight(start, end);
				return;
			}

			// Remove either one letter, or the selected text
			if (key == 'backspace') {

				if (start == end && start !== 0) {
					start = start - 1; // By moving the start pos one back we select the previous letter
				}
				key = '';
			}

			// ---------------------------
			//  End of special characters
			// ---------------------------

			if (capitals !== 0) {
				key = key.toUpperCase();

				// Shift = once, Caps = permanent
				if (capitals == 1) {
					capitals = 0;
					keyboard.classList.remove('uppercase');
					if (capKey !== null) {
						capKey.innerHTML = 'shift';
					}
				}
			}

			// update input value
			boundInput.value = value.substring(0, start) + key + value.substring(end);
			boundInput.focus();

			// Move the cursor to the position behind the value we just added
			if (start !== 0) {
				pos = start + key.length;
				boundInput.setSelectionRange(pos, pos);
			}

			// Notify the world that we registered a keystroke that changed the input value
			PubSub.publish(channels.keyboard.stroke, {
				key: key,
				newValue: boundInput.value,
				oldValue: value
			});
		};


	// ------------------------------------------------------------------------
	// 	Subscribe to external signals
	// ------------------------------------------------------------------------

	// Only subscribe to signals if we have a keyboard to work with...
	if (keyboard !== null) {

		// We start with a capital key
		toggleCapitals(capKey);

		// Capture signals to bind the keyboard to an input field
		PubSub.subscribe(channels.keyboard.bind, bindInput);

		// Capture external signals to hide the keyboard
		PubSub.subscribe(channels.keyboard.hide, hideKeyboard);

		// Capture external signals to show the keyboard
		PubSub.subscribe(channels.keyboard.show, showKeyboard);

		// Allow an external script to activate the keyboard early
		PubSub.subscribe(channels.keyboard.activate, maybeActivate);
	}

	// ------------------------------------------------------------------------
	// 	The API
	// ------------------------------------------------------------------------

	// Export the pubsub channels
	if (typeof module !== 'undefined' && module.exports) {
		module.exports = {
			channels  	 : channels.keyboard,
			boundInput 	 : getBoundField
		};
	}

}(Gator, PubSub));

