

"use strict";

(function(){

    var feedback = document.getElementById('feedback');

    if (feedback === null) {
        return;
    }

    feedback.classList.add('fadeable');

    function fade() {
        feedback.classList.add('fade');

        setTimeout(function(){
            feedback.classList.add('hidden');
        }, 2000);
    }

    feedback.addEventListener('click', fade, false);
    setTimeout(fade, 5000);
}());
