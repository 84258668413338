
"use strict";

(function() {

    var Gator = require('../vendor/gator-1.2.4.min.js'),
        PubSub = require('../vendor/pubsub.js'),
        keyboard = require('../app/tools/keyboard.js'),

        inputFields = document.querySelectorAll('.js-keyboard-input');

    if (inputFields.length !== 0) {

        // Allow all keyboard related fields to bind the keyboard
        Gator(document.body).on('click', '.js-keyboard-input', function (e) {
            PubSub.publish(keyboard.channels.bind, e.target);
        });

        // And bind & focus on the top most field
        PubSub.publish(keyboard.channels.bind, inputFields[0]);
        inputFields[0].focus();

        // Upon hitting enter we go to the next field
        PubSub.subscribe(keyboard.channels.enter, function(){
            var i;

            // Go to the next field for all but the last field
            for (i = 0; i < inputFields.length - 1; i++) {

                if (inputFields[i] === keyboard.boundInput()) {
                    i++;
                    PubSub.publish(keyboard.channels.bind, inputFields[i]);
                    inputFields[i].focus();
                    return;
                }
            }

            // Hit enter on the last field? Then we submit the form.
            inputFields[i].form.submit();
        });


    }

}());